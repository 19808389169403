import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'unit',
    'unitList',
    'totalCount',
    'addAll',
    'paginate'
  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  get contractId() { return this.unitListTarget.getAttribute('data-contract')}

  connect() {
    this.getUnits()
  }

  getUnits(event) {
    var page_nb = ''
    if (event) {
      event.preventDefault()
      page_nb = event.target.dataset.page
    }
    fetch(`/contracts/${this.contractId}/get_unlinked_units.json?page=${page_nb}`,
      {
        headers: {'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken },
        method: 'GET'
      }
    ).then(response => response.json())
      .then(data => {
        this.unitListTarget.innerHTML = ''
        
        this.totalCountTarget.innerHTML = data.total_count
        this.paginateTarget.innerHTML = ''

        var pages_count = Math.ceil(data.total_count / 50)
        if (pages_count > 1)
        {
          var pages = []
          for (var i = 1; i <= pages_count; i++) {
            pages.push(i)
          }
          pages.forEach(page => {
            var li = document.createElement('li')
            li.classList.add('page-item')
            var div = document.createElement('div')
            div.classList.add("page-link")
            div.innerText = page
            if ((page == page_nb) || (page_nb == '' && page == 1))
            { li.classList.add('active') }
            if (page != page_nb)
            { 
              li.setAttribute('data-action', 'click->contracts--filter-units#getUnits')
              li.setAttribute('data-page', page)
              div.setAttribute('data-action', 'click->contracts--filter-units#getUnits')
              div.setAttribute('data-page', page)
            }
            li.appendChild(div)
            this.paginateTarget.appendChild(li)
          })
        }

        this.addAllTarget.setAttribute('data-ids', data.units.map(unit => unit.id).join(','))
        
        data.units.forEach(unit => {
          var row = document.createElement('tr')
          var btn = document.createElement('td')
          var name = document.createElement('td')
          var address = document.createElement('td')

          btn.innerHTML = `<div class="btn btn-primary" data-action="click->contracts--filter-units#addToContract" data-id=${unit.id}>Add</button>`
          name.innerHTML = unit.name
          address.innerHTML = unit.address

          row.appendChild(btn)
          row.appendChild(name)
          row.appendChild(address)

          this.unitListTarget.appendChild(row)

      })
    })
  }

  addToContract(event) {
    fetch(`/contract_to_units.json?`,
    {
      headers: {'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken },
      method: 'POST',
      body: JSON.stringify({ contract_to_unit: { contract_id: this.contractId, unit_id: event.target.dataset.id } })
    }
  ).then(response => response.json())
    .then(data => {
    
    
      const linkedUnits = document.querySelector('#units_linked_to_contract')
      var row = document.createElement('tr')
      var name = document.createElement('td')
      var address = document.createElement('td')
      var btn = document.createElement('td')

      name.innerHTML = event.target.parentElement.parentElement.children[1].innerHTML
      address.innerHTML = event.target.parentElement.parentElement.children[2].innerHTML
      btn.innerHTML = `
      <a rel="nofollow" data-method="delete" href="/contract_to_units/${data.id}">
        <i class="far fa-trash-alt"></i>
      </a>`

      row.appendChild(name)
      row.appendChild(address)
      row.appendChild(btn)

      linkedUnits.appendChild(row)

      document.getElementById('units-linked-table').classList.remove("hidden")
      document.getElementById('no-units-linked').classList.add("hidden")

      event.target.parentElement.parentElement.remove()
      this.totalCountTarget.innerHTML = parseInt(this.totalCountTarget.innerHTML) - 1
    })
  }

  addAllToContract() {
    fetch(`/contract_to_units.json?`,
    {
      headers: {'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken },
      method: 'POST',
      body: JSON.stringify({ contract_to_unit: { contract_id: this.contractId, unit_list: this.addAllTarget.dataset.ids } })
    }
  ).then(response => response.json())
    .then(data => {

      document.getElementById('units-linked-table').classList.remove("hidden")
      document.getElementById('no-units-linked').classList.add("hidden")
      window.location.reload()
    })
  }
}