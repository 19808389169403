import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [
    'userid',
    // 'employeeids',
    'date',
    'addBtn',
    'technicians',
    'providers',
    'providerid',
    'btnTechnicians',
    'btnProviders',
    'notify'
  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  showTechnicians() {
    this.techniciansTarget.classList.remove('hidden')
    this.providersTarget.classList.add('hidden')
    this.useridTarget.dataset.usethis = true
    this.provideridTarget.dataset.usethis = false
    this.btnTechniciansTarget.classList.remove('btn-secondary')
    this.btnTechniciansTarget.classList.add('btn-primary')
    this.btnProvidersTarget.classList.remove('btn-primary')
    this.btnProvidersTarget.classList.add('btn-secondary')
    this.useridTarget.required = true
    this.provideridTarget.required = false
  }

  showProviders() {
    this.providersTarget.classList.remove('hidden')
    this.techniciansTarget.classList.add('hidden')
    this.provideridTarget.dataset.usethis = true
    this.useridTarget.dataset.usethis = false
    this.btnProvidersTarget.classList.remove('btn-secondary')
    this.btnProvidersTarget.classList.add('btn-primary')
    this.btnTechniciansTarget.classList.remove('btn-primary')
    this.btnTechniciansTarget.classList.add('btn-secondary')
    this.useridTarget.required = false
    this.provideridTarget.required = true
  }

  assign(event) {
    var userid = null
    var assignation = null
    var providerid = null
    if (this.useridTarget && this.useridTarget.dataset.usethis == 'true') {
      userid = this.useridTarget.value
      assignation = 'internal'
      providerid = null
    }
    if (this.provideridTarget && this.provideridTarget.dataset.usethis == 'true') {
      userid = null
      providerid = this.provideridTarget.value
      assignation = 'external'
    }

    // var employeeids =  Array.from(this.employeeidsTarget.options).map(option => option.value)
    var date = this.dateTarget.value
    var original_date = event.target.dataset.date
    var maintenance_id = event.target.dataset.maintenance
    var addBtn = this.addBtnTarget
    var notify = this.notifyTarget.value
    var closeBtn = addBtn.closest('.modal').querySelector('.btn-close')

    fetch('/maintenances.json', {
      method:  'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        data:
        {
          maintenance_id: maintenance_id,
          user:           userid,
          provider:       providerid,
          assignation:    assignation,
          original_date:  original_date,
          date:           date,
          notify:         notify
        }})
    })
      .then(response => response.json())
      .then(() => {
        
        if (closeBtn) {
          closeBtn.click()
        }
        if (window.calendar) {
          window.calendar.refetchEvents()
        }
      })
  }
}